/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim
* Edited by : IT Paragon
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from 'axios';
import md5 from 'md5';
import Cookies from 'universal-cookie'
// react library for routing
import { Link, Redirect } from "react-router-dom";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import { Button,Card,CardHeader,CardBody,FormGroup,Form,
        Input,InputGroupAddon,InputGroupText,InputGroup,
        Container,Row,Col } from "reactstrap";
// core components
import AuthHeaderSignin from "components/Headers/AuthHeaderSignin.jsx";

import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

export class IndexHeader extends React.Component {
	constructor() {
    const cookies = new Cookies()
		super();
		this.state = {
      userloggedin: null,
			login_status: null,
			username: null,
			password: null,
      encrypt_email: null,
      encrypt_pass: null,
      login: false,
      messageSignin: ''
		};
	}

  componentDidMount() {

	}

  handleChange = input => e => {
	    this.setState({ [input]: e.target.value });
	};

  encrypt = (event,obj) => {
    var encrypted = ""
    var keyLength = 100

    for (var i = 0; i < event.length; i++) {
      var ch = event.charAt(i)
      var ascii = event.charCodeAt(i)
      if(ascii >= 65 && ascii <= 90){
        var ascii = ascii + (keyLength%26)
        if(ascii > 90) {ascii = ascii - 26}
      } else if(ascii >= 97 && ascii <= 122){
        var ascii = ascii + (keyLength%26)
        if(ascii > 122) {ascii = ascii -26}
      }
      var res = String.fromCharCode(ascii);
      encrypted = encrypted+res;
    }
    return encrypted
  }

  handleChangeInputEmail = event =>{
    console.log(event.target.value)
    this.setState({
			username: event.target.value,
      encrypt_email: this.encrypt(event.target.value),
		})
  }

  handleChangeInputPassword = event =>{
    // console.log(event.target.value)
    this.setState({
			password: event.target.value,
      encrypt_pass: this.encrypt(event.target.value),
		})
  }

  signInClicked = event => {
    const qs = require('querystring')
    var credential = this.state.encrypt_email+""+this.state.encrypt_pass

    const requestBody = {
      authentication: credential
    }

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    axios.post('https://booking-room.pti-cosmetics.com:9666/login', qs.stringify(requestBody), config)
    .then(ldap => {
      console.log(ldap.data)
      console.log('DONE : GET RESPONSE FROM LDAP')
      const cookies = new Cookies();

      axios.get('https://training-api.pti-cosmetics.com/one_user?email=eq.'+this.state.username)
      .then(response => {
        console.log(response.data)
        console.log(response.data.length)
        if(response.data.length > 0){
          if(response.data[0].is_active){
            cookies.set('userloggedin', response.data, {path: '/'});
            // cookies.set('login_status', response.data.status, {path: '/'});
            cookies.set('login_status', response.data[0].is_active, {path: '/'});
            cookies.set('user_email', this.state.username, {path: '/'});
            this.setState({
              userloggedin: response.data,
              login_status: response.data[0].is_active
            })
          } else {
            this.setState({
              messageSignin: 'User Ideabox diatas tidak aktif, silahkan hubungi IT Paragon'
            })
          }
        } else {
          this.setState({
            messageSignin: 'User tidak termasuk Innovation Facilitator, silahkan hubungi IT Paragon untuk lebih lanjut'
          })
        }
      })
      .catch(error => {
        console.log(error)
      })

    })
    .catch(error => {
        console.log(error)
        console.log('error',error.response)
        console.log('ERROR : NOT GET RESPONSE')
        this.setState({
          messageSignin: 'Mohon masukkan email dan password dengan benar'
        })
    });

    console.log('finish to auth')
  }

  render() {
    // console.log(this.state.username)
    // console.log(this.state.password)
    // console.log(this.state.encrypt_email)
    // console.log(this.state.encrypt_pass)
    // console.log(this.state.userloggedin)
    console.log(this.state.login_status)
    const messageSignin = this.state.messageSignin;

    if(this.state.login_status){
      console.log('BERHASIL LOGIN')
      return(
        <Redirect from="*" to="/admin/uploadcontent"/>
      )
    } else {
      return (
        <>
          <div className="header bg-info pt-1 pb-1">
            <Container>
              <div className="header-body">
                <Row className="align-items-center">
                  <Col lg="6">
                    <div className="pr-5">

                      <h1 className="display-2 text-white font-weight-bold mb-0">
                        <b>CMS WardahOne</b>
                      </h1>
                      <h1 className="display-4 text-white font-weight-bold mb-0">
                        Version 1.0 beta
                      </h1>
                      <h2 className="display-4 text-white font-weight-light">
                        PT Paragon Technology and Innovation
                      </h2>
                      <p className="text-white mt-4">
                        Content Management System WardahOne
                      </p>
                      <div className="mt-5">
                        <Button
                          className="btn-neutral my-2"
                          color="default"
                          to="/admin/dashboard"
                          tag={Link}
                        >
                          Explore Dashboard
                        </Button>
                        <Button
                          style={{width: '30%',}}
                          className="my-2"
                          color="default"
                          href="https://link.wardahbeauty.com"
                          target="_blank"
                        >
                          WardahOne
                        </Button>
                      </div>
                    </div>
                  </Col>
                  <Col lg="6">
                    <Row className="justify-content-center">
                      <AuthHeaderSignin
                        title="Welcome!"
                        lead=<i><b>"Please Login to See CMS WardahOne"</b></i>
                      />
                      <Container className="mt--7 pb-5" >
                        <Row className="justify-content-center">
                          <Col lg="9" md="7" >
                            <Card className="bg-secondary border-0 mb-0">

                              <CardBody className="px-lg-5 py-lg-5">
                                <div className="text-center text-muted mb-4">
                                  <small>Sign In Here</small>
                                </div>
                                <Form role="form">
                                  <FormGroup
                                    className={classnames("mb-3", {
                                      focused: this.state.focusedEmail

                                    })}
                                    onChange={this.handleChangeInputEmail.bind(this)}
                                  >
                                    <InputGroup className="input-group-merge input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="ni ni-email-83" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input
                                        placeholder="Username"
                                        type="email"
                                        onFocus={() => this.setState({ focusedEmail: true })}
                                        onBlur={() => this.setState({ focusedEmail: false })}
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  <FormGroup
                                    className={classnames({
                                      focused: this.state.focusedPassword
                                    })}
                                    onChange={this.handleChangeInputPassword.bind(this)}
                                  >
                                    <InputGroup className="input-group-merge input-group-alternative">
                                      <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                          <i className="ni ni-lock-circle-open" />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                      <Input
                                        placeholder="Password"
                                        type="password"
                                        onFocus={() =>
                                          this.setState({ focusedPassword: true })
                                        }
                                        onBlur={() =>
                                          this.setState({ focusedPassword: false })
                                        }
                                      />
                                    </InputGroup>
                                  </FormGroup>
                                  <div className="custom-control custom-control-alternative custom-checkbox">
                                    <input
                                      className="custom-control-input"
                                      id=" customCheckLogin"
                                      type="checkbox"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor=" customCheckLogin"
                                    >
                                      <span className="text-muted">Remember me</span>
                                    </label>
                                  </div>

                                  {(function(){
                                    if(messageSignin !== null){
                                      return(
                                        <p style={{
                                          textAlign: 'center',
                                          fontWeight:'300',
                                          fontSize: '12px',
                                          color: '#e50000',
                                          marginTop:'20px',
                                          marginBottom:'0px'
                                        }}>
                                          {messageSignin}
                                        </p>
                                      )
                                    }
                                  })()}

                                  <div className="text-center">
                                    <Button
                                      className="my-4"
                                      color="info"
                                      type="button"
                                      onClick={this.signInClicked.bind(this)}
                                    >
                                      Sign in
                                    </Button>
                                  </div>
                                </Form>
                              </CardBody>
                            </Card>
                            <Row className="mt-3">
                              <Col xs="6">
                                <a
                                  className="text-white mt-4"
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  <small>
                                    Forgot password?
                                  </small>
                                </a>
                              </Col>
                              <Col className="text-right" xs="6">
                                <a
                                  className="text-white mt-4"
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  <small>
                                    Create new account
                                  </small>
                                </a>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Container>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Container>

          </div>
        </>
      );
    }

  }
}

export default IndexHeader;
