/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from "axios";
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import ImageUploader from 'react-images-upload';

import Swal from 'sweetalert2';
import { makeStyles, Typography, TextField, FormControl } from '@material-ui/core';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import ManageMateriHeader from "components/Headers/ManageMateriHeader.jsx";
import ManageMateriInaco from "views/pages/tables/ManageMateriInaco.jsx";

export class Profile extends React.Component {
	constructor() {
		super();
		this.state = {
			userloggedin : {},
			color : [],
		}
	}

  componentDidMount(){
    
  }

  render() {

    return (
      <>
        <ManageMateriHeader />
        <ManageMateriInaco/>
      </>
    );
  }
}

export default Profile;
