import React from "react";
// react plugin that prints a given react component

 import { Line, Bar, Doughnut, Pie } from "react-chartjs-2";
//import ReactToPrint from "react-to-print";
import { Link } from "react-router-dom";
import { Chart } from "react-google-charts";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
import Cookies from 'universal-cookie'

import Swal from 'sweetalert2';
import axios from "axios";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  FormGroup,
  Form,
  Input,
  Modal,
  Row,
  Col,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529"
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340"
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent"
};

const pagination = paginationFactory({
  page: 1,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show{" "}
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            onChange={e => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }{" "}
        entries.
      </label>
    </div>
  )
});

const { SearchBar } = Search;

class ReactBSTables extends React.Component {

  constructor(props) {
    super();
    this.state = {
      alert: null,
      dataContent: [],
      listcontent: [],
      openModalDeleteMateri: false,
      openModalClickCountDaily: false,
      openModalEditMateri: false,
      ID_deleteMateri: null,

      ID_editMateri: null,
      Title_editMateri: null,
      Section_editMateri: null,
      Order_editMateri: null,
      Active_editMateri: null,
      Link_editMateri: null,
      Desc_editMateri: null,
      listActiveOption : [],
      checkUpdateContent : false,

      Materi_editMateri: null,
      clickCountLastMonth: null,
    }
    this.handleChangeActive = this.handleChangeActive.bind(this)
  }

  componentDidMount(){
    console.log('DID MOUNT')
    axios.get('https://training-api.pti-cosmetics.com/one_content_submission?order=id.desc&title=not.is.null')
    .then(response => {
      console.log(response.data)
      this.setState({
        dataContent: response.data
      })
    })
    .catch(error => {
      console.log(error)
    })

    axios.get('https://training-api.pti-cosmetics.com/one_list_content')
    .then(response => {
      console.log(response.data)
      this.setState({
        listcontent : response.data
      })
    })
    .catch(error => {
      console.log(error)
    })

    var listactiveoption = [ 'Enable', 'Disable' ]
    this.setState({
      listActiveOption : listactiveoption
    })
  }

  ShowSectionContent(cell, row){
    console.log(row.id)
    var content
    this.state.listcontent.map((item) =>{
			if (row.section == item.id) {
        content = item.content
      }
		})
    console.log(content)
    return (
        <div>
          <p style={{
            fontSize: '14px',
            fontWeight:'200',
            fontFamily: 'Arial',
            marginTop:'0px'
          }}>
            {content}
          </p>
        </div>
    );
  }

  ShowLinkThisLine(cell, row) {
    console.log(row)
    return (
        <div>
          <a href={row.link} target="_blank">
            <button
              type="button"
              className="btn btn-info btn-sm ts-buttom"
              size="sm"
            >
              Link here
            </button>
          </a>
        </div>
    );
  }

  EnableDisableThisLine(cell, row) {
    console.log(row)
    if (row.active){
      return (
          <div>
            <button
              type="button"
              className="btn btn-success btn-sm ts-buttom"
              size="sm"
            >
              Enable
            </button>
          </div>
      );
    } else {
      return (
          <div>
            <button
              type="button"
              className="btn btn-danger btn-sm ts-buttom"
              size="sm"
            >
              Disable
            </button>
          </div>
      );
    }
  }

  ShowTumbnailThisLine(cell, row) {
    console.log(row)
    return (
        <div>
          <img style={{
            width:'100px'
          }}
            src={row.image}
          />
        </div>
    );
  }

  GetActionFormat(cell, row) {
    return (
      <div>
        <Row style={{marginBottom:'10px'}}>
          <button
            type="button"
            className="btn btn-primary btn-sm ts-buttom"
            size="sm"
            onClick={this.openModaleditMateriChoosed.bind(this,cell,row)}
          >
            Edit
          </button>
        </Row>

        <Row style={{marginBottom:'10px'}}>
          <button
            type="button"
            className="btn btn-warning btn-sm ts-buttom"
            size="sm"
            onClick={this.openModaldeleteMateriChoosed.bind(this,cell,row)}
          >
            Delete
          </button>
        </Row>

        <Row>
          <button
            type="button"
            className="btn btn-outline-primary btn-sm ts-buttom"
            size="sm"
            onClick={this.openModalClickCount.bind(this,cell,row)}
          >
            ClickCount
          </button>
        </Row>
      </div>
    );
  }
  // onClick={this.deleteMateriChoosed.bind(this,cell,row)}

  openModaldeleteMateriChoosed = (cell, partner, rowIndex) => {
    console.log(partner)
    this.setState({
      openModalDeleteMateri : true,
      ID_deleteMateri : partner.id,
    })
  }

  closeModaldeleteMateriChoosed = () => {
    this.setState({
      openModalDeleteMateri : false,
      ID_deleteMateri : null,
    })
  }

  openModalClickCount = (cell, partner, rowIndex) => {
    console.log(partner)
    console.log(partner.id)
    console.log(partner.section)
    console.log('Open click count modal = TRUE')
    axios.get('https://training-api.pti-cosmetics.com/v_one_clickcount_daiy?id_content=eq.'+partner.id+'&id_section=eq.'+partner.section)
		.then(response =>{
      console.log('CLICK COUNT DAILY DATA')
      console.log(response.data)

      var dateFormat = require('dateformat')
  		var today = new Date();
  		var todayDate = dateFormat(today, "yyyy-mm-dd")
  		var todayDateDay = dateFormat(today, "dd")
  		var todayDateMonth = dateFormat(today, "mm")
      // var lastMonth = new Date(today.getFullYear(), today.getMonth()-1, today.getDate())
      var lastMonth = today.getMonth()
      console.log(todayDate)
  		console.log(todayDateDay)
  		console.log(todayDateMonth)
  		console.log(lastMonth)

      var arrayClickLastMonth = []
      for (var i = 1; i <= 31; i++) {
          var checkInputDate = false
          response.data.map((item) =>{
            console.log(item)
            var getMonth = item.date.slice(5,7)
            var getDay = item.date.slice(8,10)

            var i_dateformat = i.toString()
            if(i.toString().length == 1){
              i_dateformat = '0'.concat(i_dateformat)
            }
            if(getMonth === lastMonth.toString() && getDay === i_dateformat){
              arrayClickLastMonth.push(item.count)
              checkInputDate = true
            }
      		})
          if (checkInputDate == false){
            arrayClickLastMonth.push(0)
          }
      }
      console.log(arrayClickLastMonth)

      var clickCountLastMonth = {
        options: {
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: colors.gray[200],
                  zeroLineColor: colors.gray[200]
                },
                ticks: {}
              }
            ]
          }
        },
        data: {
          labels: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
          datasets: [
            {
              label: "Click Count Last Month",
              data: arrayClickLastMonth
            }
          ]
        }
      };

      var arrayClickThisMonth = []
      for (var i = 1; i <= 31; i++) {
          var checkInputDate = false
          response.data.map((item) =>{
            console.log(item)
            var getMonth = item.date.slice(5,7)
            var getDay = item.date.slice(8,10)

            var i_dateformat = i.toString()
            if(i.toString().length == 1){
              i_dateformat = '0'.concat(i_dateformat)
            }
            if(getMonth === todayDateMonth && getDay === i_dateformat){
              arrayClickThisMonth.push(item.count)
              checkInputDate = true
            }
          })
          if (checkInputDate == false){
            arrayClickThisMonth.push(0)
          }
      }
      console.log(arrayClickThisMonth)

      var clickCountThisMonth = {
        options: {
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: colors.gray[200],
                  zeroLineColor: colors.gray[200]
                },
                ticks: {}
              }
            ]
          }
        },
        data: {
          labels: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
          datasets: [
            {
              label: "Click Count Last Month",
              data: arrayClickThisMonth
            }
          ]
        }
      };

      this.setState({
        openModalClickCountDaily : true,
        ID_deleteMateri : partner.id,
        clickCountLastMonthData : clickCountLastMonth.data,
        clickCountLastMonthOptions : clickCountLastMonth.options,
        clickCountThisMonthData : clickCountThisMonth.data,
        clickCountThisMonthOptions : clickCountThisMonth.options,
      })

    })
		.catch(error => {
			console.log(error)
		})
  }

  closeModalClickCount = () => {
    this.setState({
      openModalClickCountDaily : false,
      ID_deleteMateri : null,
    })
  }

  deleteMateriChoosed = () => {
    console.log("Delete Materi")
    console.log(this.state.ID_deleteMateri)
    axios.delete('https://training-api.pti-cosmetics.com/one_content_submission?id=eq.'+this.state.ID_deleteMateri)
		.then(response =>{
      console.log('DELETE CONTENT BERHASIL')
			window.location.reload();
		})
		.catch(error => {
			console.log(error)
		})
  }

  openModaleditMateriChoosed = (cell, partner, rowIndex) => {
    // console.log(cell)
    console.log(partner)
    // console.log(rowIndex)
    console.log(this.state.listcontent)
    var section_name
    this.state.listcontent.map(row => {
      if(row.id == partner.section){
        section_name = row.content
      }
    })
    // console.log(section_name)
    var show
    if(partner.active){
        show = 'Enable'
    } else {
      show = 'Disable'
    }
    this.setState({
      openModalEditMateri : true,
      ID_editMateri : partner.id,
      Title_editMateri : partner.title,
      Section_editMateri : section_name,
      Order_editMateri : partner.order,
      Active_editMateri : show,
      Link_editMateri : partner.link,
      Desc_editMateri : partner.description,
      Materi_editMateri : partner,
    })
  }

  closeModaleditMateriChoosed = () => {
    this.setState({
      openModalEditMateri : false,
      ID_editMateri : null,
      Title_editMateri: null,
      Section_editMateri: null,
      Order_editMateri: null,
      Active_editMateri: null,
      Link_editMateri: null,
      Desc_editMateri: null,
      Materi_editMateri : null,
    })
  }

  handleChangeTitle = event => {
    // console.log(event.target.value);
    this.setState({
      Title_editMateri : event.target.value,
      checkUpdateContent : true
    })
  }

  handleChangeLink = event => {
    // console.log(event.target.value);
    this.setState({
      Link_editMateri : event.target.value,
      checkUpdateContent : true
    })
  }
  handleChangeOrder = event => {
    // console.log(event.target.value);
    this.setState({
      Order_editMateri : event.target.value,
      checkUpdateContent : true
    })
  }
  handleChangeActive = event => {
    console.log(event.target.value);
    this.setState({
      Active_editMateri : event.target.value,
      checkUpdateContent : true
    })
  }
  handleChangeDescription = event => {
    // console.log(event.target.value);
    this.setState({
      Desc_editMateri : event.target.value,
      checkUpdateContent : true
    })
  }

  handleClickUpdateMaterial = event => {
    if (this.state.checkUpdateContent == false){
      Swal.fire({
        icon: 'error',
        text: 'Nothing to Update!',
        confirmButtonColor: '#e55555',
      });
    } else {
      var active
      if(this.state.Active_editMateri == 'Enable'){
          active = true
      } else { //this.state.Active_editMateri == 'Disable'
        active = false
      }
      var materi = {
        "title" : this.state.Title_editMateri,
        "order" : this.state.Order_editMateri,
        "active" : active,
        "link" : this.state.Link_editMateri,
        "description" : this.state.Desc_editMateri,
      }
      console.log(materi)
      console.log(this.state.ID_editMateri)
      axios.patch('https://training-api.pti-cosmetics.com/one_content_submission?id=eq.'+this.state.ID_editMateri, materi)
      .then(response => {
        console.log("SUBMIT UPDATE BERHASIL")
        Swal.fire({
         icon: 'success',
         text: 'Your Materials has been updated',
         confirmButtonColor: 'blue',
        });
        window.location.reload();
      })
      .catch(error => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          text: 'Materi can`t be updated!',
          confirmButtonColor: '#e55555',
        });
      })
    }
  }

  render() {
  console.log(this.state.Active_editMateri)
    console.log(this.state.listActiveOption)

    const listcontent = this.state.listcontent
    const listActiveOption = this.state.listActiveOption

    return (
      <>
        {this.state.alert}
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">WardahOne</h3>
                </CardHeader>
                <ToolkitProvider
                  data={this.state.dataContent}
                  keyField="name"
                  columns={[
                    {
                      dataField: "id",
                      text: "ID",
                      sort: true,
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "title",
                      text: "Title",
                      sort: true,
                      style:{
                        width: '200px',
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      text: "Section",
                      dataField: "",
                      formatter:this.ShowSectionContent.bind(this),
                      sort: true,
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "link",
                      text: "Link",
                      sort: true,
                      formatter:this.ShowLinkThisLine.bind(this),
                      style:{
                        width: '200px',
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "active",
                      text: "Show",
                      sort: true,
                      formatter:this.EnableDisableThisLine.bind(this),
                      style:{
                        width: '100px',
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "order",
                      text: "Order",
                      sort: true,
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "click",
                      text: "Click",
                      sort: true,
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "description",
                      text: "Description",
                      sort: true,
                      style:{
                        width: '400px',
                        whiteSpace: 'unset',
                        textAlign: 'justify'
                      }
                    },
                    {
                      text: "Tumbnail",
                      dataField: "",
                      formatter:this.ShowTumbnailThisLine.bind(this),
                      style:{
                        width: '200px',
                        whiteSpace: 'unset',
                        textAlign: 'justify'
                      }
                    },
                    {
                      text: "Action",
                      dataField: "",
                      formatter:this.GetActionFormat.bind(this),
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                    {
                      dataField: "email",
                      text: "Uploaded by",
                      sort: true,
                      style:{
                        whiteSpace: 'unset'
                      }
                    },
                  ]}
                  search
                >
                  {props => (
                    <div className="py-4 table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter px-4 pb-1"
                      >
                        <label>
                          Search:
                          <SearchBar
                            className="form-control-sm"
                            placeholder=""
                            {...props.searchProps}
                          />
                        </label>
                      </div>
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={false}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </Row>
        </Container>

        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.openModalClickCountDaily}
          style={{minwidth:'80%'}}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Click count daily
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick = {this.closeModalClickCount.bind(this)}
            >
              <span aria-hidden={true}>×</span>
            </button>

          </div>
          <div className="modal-body">
            <Line
               data={this.state.clickCountLastMonthData}
               options={this.state.clickCountLastMonthOptions}
               id="chart-sales"
               className="chart-canvas"
            />
            <div style={{marginTop:'30px'}}/>
            <Line
               data={this.state.clickCountThisMonthData}
               options={this.state.clickCountThisMonthOptions}
               id="chart-sales"
               className="chart-canvas"
            />

          </div>
          <div className="modal-footer">
            <Button
              className="btn-primary"
              color="default"
              type="button"
              onClick = {this.closeModalClickCount.bind(this)}
            >
              Close
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered modal-danger"
          contentClassName="bg-gradient-danger"
          isOpen={this.state.openModalDeleteMateri}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              Your attention is required
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick = {this.closeModaldeleteMateriChoosed.bind(this)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="ni ni-bell-55 ni-3x" />
              <h4 className="heading mt-4">Are you sure ?</h4>
              <p>
                Materi beserta data datanya akan dihapus secara permanen
              </p>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick = {this.deleteMateriChoosed.bind(this)}
            >
              Ok, Delete!
            </Button>
            <Button
              className="text-white ml-auto"
              color="link"
              data-dismiss="modal"
              type="button"
              onClick = {this.closeModaldeleteMateriChoosed.bind(this)}
            >
              Back
            </Button>
          </div>
        </Modal>

        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.openModalEditMateri}
        >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">
              {this.state.Title_editMateri}
            </h6>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick = {this.closeModaleditMateriChoosed.bind(this)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div>
                <h6 className="heading-small text-muted mb-4">
                  Edit Materi
                </h6>
                <div >
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          Title / Name
                        </label>
                        <Input
                          defaultValue={this.state.Title_editMateri}
                          id="input-country"
                          placeholder="Title"
                          type="text"
                          onChange={this.handleChangeTitle.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          Section Content
                        </label>
                        <Input
                          defaultValue={this.state.Section_editMateri}
                          id="input-country"
                          placeholder="Title"
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          Order
                        </label>
                        <Input
                          defaultValue={this.state.Order_editMateri}
                          id="input-country"
                          placeholder="Order"
                          type="text"
                          onChange={this.handleChangeOrder.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                        >
                          Enable / Disable Show Materi
                        </label>
                        <Input
                          type="select"
                          value={this.state.Active_editMateri}
                          onChange={this.handleChangeActive}
                        >
                          <option value='Enable' > Enable </option>
                          <option value='Disable' > Disable </option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-country"
                        >
                          Link
                        </label>
                        <Input
                          defaultValue={this.state.Link_editMateri}
                          id="input-country"
                          placeholder="Link"
                          type="text"
                          onChange={this.handleChangeLink.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">Description</label>
                        <Input
                          defaultValue={this.state.Desc_editMateri}
                          rows="4"
                          type="textarea"
                          onChange={this.handleChangeDescription.bind(this)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          </div>
          <div className="modal-footer">
            <Button
              className="btn-white"
              color="default"
              type="button"
              onClick = {this.closeModaleditMateriChoosed.bind(this)}
            >
              Back
            </Button>
            <Button
              className="ml-auto"
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick = {this.handleClickUpdateMaterial.bind(this)}
            >
              Update Materi
            </Button>
          </div>
        </Modal>

      </>
    );
  }
}

export default ReactBSTables;
