/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import axios from "axios";
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import ImageUploader from 'react-images-upload';

import Cookies from 'universal-cookie'
import Swal from 'sweetalert2';
import { makeStyles, Typography, TextField, FormControl } from '@material-ui/core';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import SubmitMateriHeader from "components/Headers/SubmitMateriHeader.jsx";

export class Profile extends React.Component {
	constructor() {
    const cookies = new Cookies()
		super();
		this.state = {
			userloggedin : {},
			color : [],
      title : null,
      content : null,
      filelink : null,
      description : null,
      email : cookies.get('user_email'),
      pictureDataURLs: null,
			isNewUpload: false,
      listcontent : [],
		}
	}

  componentWillMount(){
    axios.get('https://training-api.pti-cosmetics.com/one_list_content')
    .then(response => {
      console.log(response.data)
      this.setState({
        listcontent : response.data
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  onDrop(pictureFiles, pictureDataURLs) {
		//var idx_picture = pictureFiles.length - 1
		this.setState({
			isNewUpload: false,
		});

    console.log('FILES')
    console.log(pictureFiles)
    console.log(pictureDataURLs)

    if(pictureDataURLs !== undefined){
			this.setState({
	      pictures: pictureFiles,
				pictureDataURLs: pictureDataURLs[pictureFiles.length - 1],
				isNewUpload: true,
	    });
		}
  }

  handleChangeTitle = event => {
    // console.log(event.target.value);
    this.setState({
      title : event.target.value,
    })
  }
  handleChooseContent = event => {
    console.log(event.target.value);
    this.setState({
      content : parseInt(event.target.value),
    })
  }
  handleChangeLink = event => {
    // console.log(event.target.value);
    this.setState({
      filelink : event.target.value,
    })
  }
  handleChangeDescription = event => {
    // console.log(event.target.value);
    this.setState({
      description : event.target.value,
    })
  }

  handleClickSubmitMaterial = event => {
    if (this.state.title == null || this.state.content == null || this.state.content == "0" || this.state.content == 0 || this.state.filelink == null || this.state.pictureDataURLs == null){
      console.log('Complete your forms')
      Swal.fire({
        icon: 'error',
        text: 'Complete your forms!',
        confirmButtonColor: '#e55555',
      });
    } else {
      var materi = {
        "title" : this.state.title,
        "section" : this.state.content,
        "link" : this.state.filelink,
        "description" : this.state.description,
        "image" : this.state.pictureDataURLs,
        "email" : this.state.email,
        "click" : 0,
        "order" : 0,
        "active" : true
      }
      axios.post('https://training-api.pti-cosmetics.com/one_content_submission', materi)
      .then(response => {
        console.log("SUBMIT UPDATE BERHASIL")
        // console.log(response.data)
        Swal.fire({
         icon: 'success',
         text: 'Your Materials has been submitted',
         confirmButtonColor: 'blue',
        });
        window.location.reload();
      })
      .catch(error => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          text: 'Materi cant be submitted!',
          confirmButtonColor: '#e55555',
        });
      })
    }
  }


  render() {
    // console.log(this.state.title)
    // console.log(this.state.content)
    // console.log(this.state.filelink)
    // console.log(this.state.description)
    console.log(this.state.listcontent)

    const listcontent = this.state.listcontent

    return (
      <>
        <SubmitMateriHeader />
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">

              <Card>
                <CardHeader>
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">
                        New Content WardahOne
                      </h3>
                    </Col>
                    <Col className="text-right" xs="4">
                      <Button
                        color="primary"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                        size="sm"
                      >
                        Button
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <div>
                      <h6 className="heading-small text-muted mb-4">
                        Upload Content
                      </h6>
                      <div className="pl-lg-4">
                        <Row>
                          <Col lg="6">
                            <Row>
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-country"
                                  >
                                    Title / Name
                                  </label>
                                  <Input
                                    defaultValue=""
                                    id="input-country"
                                    placeholder="Title"
                                    type="text"
                                    onChange={this.handleChangeTitle.bind(this)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="exampleFormControlSelect3"
                                  >
                                    Section Content
                                  </label>
                                  <Input
                                    id="exampleFormControlSelect3"
                                    type="select"
                                    onChange={this.handleChooseContent.bind(this)}
                                  >
                                    <option value='0'> Choose Section </option>
                                    {listcontent.map((row)=> (
                                      <option value={row.id}> {row.content} </option>
                                    ))}
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-country"
                                  >
                                    Link
                                  </label>
                                  <Input
                                    defaultValue=""
                                    id="input-country"
                                    placeholder="Link"
                                    type="text"
                                    onChange={this.handleChangeLink.bind(this)}
                                  />
                                </FormGroup>
                              </Col>
                              <Col lg="12">
                                <FormGroup>
                                  <label className="form-control-label">Description</label>
                                  <Input
                                    placeholder="Description here"
                                    rows="4"
                                    type="textarea"
                                    defaultValue=""
                                    onChange={this.handleChangeDescription.bind(this)}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg="6">
                            <div>
                              <label
                                className="form-control-label"
                                htmlFor="input-country"
                              >
                                Upload Tumbnail
                              </label>
                              <ImageUploader
                                withIcon={true}
                                buttonText='Choose images'
                                label='Max image size: 1 Mb (jpg,png,gif,jpeg)'
                                withPreview={true}
                                onChange={this.onDrop.bind(this)}
                                singleImage={true}
                                sizeFactor = {0.3}
                                imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                                maxFileSize={1000000}
                                style={{padding:'0px'}}
                              />
                            </div>
                          </Col>
                          <Col lg="12">
                            <Button
                              color="primary"
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                              size="lg"
                              style={{marginBottom:'20px'}}
                              onClick={this.handleClickSubmitMaterial.bind(this)}
                            >
                              Submit Materi
                            </Button>
                          </Col>
                        </Row>

                      </div>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

      </>
    );
  }
}

export default Profile;
